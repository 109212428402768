<template lang="pug">
  span
    v-btn(color="error"
      @click="showSimilar"
      :loading="loading"
      :disabled="!similar.length"
    )  Matches
    span(v-show="similar.length").error--text  {{ similar.length }} found
    v-dialog(v-model="showing")
      v-card
        v-card-title
          v-btn(icon @click="hideSimilar")
            v-icon mdi-arrow-left
          span Similar candidates
        v-card-text
          v-data-table(
            :headers="headers"
            :items="similar"
            item-key="ID"
            hide-default-footer
            :mobile-breakpoint='NaN'
            class="app-table mt-4 elevation-1"
          )
            template(v-slot:item="{item, index}")
              row(
                :item="item"
                :index="index"
                :key="index"
              )
        v-card-actions
          v-spacer
          v-btn(color="error" text @click="hideSimilar") Close
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  mixins: [errorsMixin],

  props: {
    ID: {
      type: Number,
      required: true
    },
    candidateNumber: [Number, String]
  },

  data: () => ({
    similar: [],
    loading: false,
    showing: false,

    headers: [
      {text: 'CDN', value: 'candidate_number', align: 'left', class: 'nowrap cell-placed-paid', sortable: false},
      {text: 'Date of Birth', value: 'dOB', align: 'left', class: 'nowrap cell-placed-paid', sortable: false},
      {text: 'Name', value: 'candidateName', align: 'left', class: 'cell-name', sortable: false},
      {text: 'Email', value: 'candidateEmail', class: 'nowrap cell-bday', sortable: false},
      {text: 'Phone Number', value: 'edit', class: 'app-table__h_small cell-btns', sortable: false},

    ],
  }),

  inject: ['svc'],

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      if (!this.candidateNumber) this.loadList()
    },

    async loadList() {
      try {
        this.loading = true
        this.similar = await this.svc().findSimilar(this.ID)
        this.loading = false
      } catch (error) {
        console.log('error')
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    showSimilar() {
      this.showing = true
    },

    hideSimilar() {
      this.showing = false
    }
  },

  components: {
    row: () => import('./CandidateRowSimilar.vue')
  }
}
</script>
